.disclosure {
  position: relative;
}

.disclosure__button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 4rem;
  padding: 0 1.5rem 0 1.5rem;
  font-size: 1.3rem;
  background-color: transparent;
}

.disclosure__list-wrapper {
  border-width: var(--popup-border-width);
  border-style: solid;
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  overflow: hidden;
  position: absolute;
  bottom: 100%;
  transform: translateY(-1rem);
  z-index: 2;
  background-color: rgb(var(--color-background));
  border-radius: var(--popup-corner-radius);
  box-shadow: var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius) rgba(var(--color-shadow), var(--popup-shadow-opacity));
}

.disclosure__list {
  position: relative;
  overflow-y: auto;
  font-size: 1.4rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  scroll-padding: 0.5rem 0;
  min-height: 8.2rem;
  max-height: 19rem;
  max-width: 22rem;
  min-width: 12rem;
  width: max-content;
}

.disclosure__item {
  position: relative;
}

.disclosure__link {
  display: block;
  padding: 0.5rem 2.2rem;
  text-decoration: none;
  line-height: calc(1 + 0.8 / var(--font-body-scale));
}
